import { defu } from "defu";

const getDefaultDateTimeFormats = (
  options: Record<string, Intl.DateTimeFormatOptions> = {},
): Record<string, Intl.DateTimeFormatOptions> => {
  return defu(options, {
    timeShort: {
      timeStyle: "short",
    },
    short: {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    },
    long: {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    },
  } as Record<string, Intl.DateTimeFormatOptions>);
};

const getDefaultNumberFormats = (
  options: Record<string, Intl.NumberFormatOptions> = {},
): Record<string, Intl.NumberFormatOptions> => {
  return defu(options, {
    currency: {
      currencyDisplay: "symbol",
      style: "currency",
      currencySign: "$",
      notation: "standard",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  } as Record<string, Intl.NumberFormatOptions>);
};

export default {
  fallbackWarn: false,
  missingWarn: false,
  datetimeFormats: {
    en: getDefaultDateTimeFormats(),
    ar: getDefaultDateTimeFormats(),
    es: getDefaultDateTimeFormats(),
    fr: getDefaultDateTimeFormats(),
    hi: getDefaultDateTimeFormats(),
    ja: getDefaultDateTimeFormats(),
    pt: getDefaultDateTimeFormats(),
  },
  numberFormats: {
    en: getDefaultNumberFormats(),
    ar: getDefaultNumberFormats(),
    es: getDefaultNumberFormats(),
    fr: getDefaultNumberFormats(),
    hi: getDefaultNumberFormats(),
    ja: getDefaultNumberFormats(),
    pt: getDefaultNumberFormats(),
  },
};
